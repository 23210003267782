.modal {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.modal_content {
    position: relative;
    margin: 0 auto;
    width: 40ex;
    max-height: 50ex;

    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: hidden;
}

.close {
    content: 'x';
    cursor: pointer;
    position: absolute;
    right: 0ex;    
    top: 0ex;
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}