.ArtCard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40ex;
    max-height: 45ex;
    float: left;
    margin: 6px;
    margin-top: 0px;
}

.ArtCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

img{
    max-width: 70%;
    max-height: 30ex;
}

.container {
    padding: 2px 16px;
}